import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Headline, StyledHeadlineWrapper } from "../components/headline"
import SEO from "../components/seo"
import { Contact } from "../components/contact"

export default ({ data }) => (
  <Layout>
    <SEO title={data.wpPage.title}/>
    <div className={"container"}>
      <div className={"row justify-content-center"}>
        <div className={"col-10 mb-5"}>
          <StyledHeadlineWrapper>
            <Headline
              headlineH1={data.wpPage.acfPageContent.headline}
              subline={data.wpPage.acfPageContent.subline}
            />
          </StyledHeadlineWrapper>
          <div dangerouslySetInnerHTML={{ __html: data.wpPage.acfPageContent.content }}/>
        </div>
      </div>
    </div>
    <Contact/>
  </Layout>
)

export const query = graphql`
    query ($slug: String!) {
        wpPage(slug: {eq: $slug}) {
            date(formatString: "MMMM Do, YYYY")
            title
            content
            slug
            acfPageContent {
                style
                content
                headline
                subline                
            }
        }
    }
`
